import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const about = {
	initLoad() {
		this.initScrollAnimation();
	},

	initScrollAnimation() {
		if($('.about-cards').length){
			gsap.to(".about-card-1", {
				scrollTrigger: {
					trigger: ".about-cards",
					pin: true,
					scrub: true,
					start: 'bottom 95%',
					// markers: true
				},
				yPercent: -77
			});
		}
	},

}

module.exports = about;
