const wc = {
	initLoad() {
		this.removeBounceOnCart();
	},

	removeBounceOnCart() {
		$('.cart-contents').removeClass('bounce')
	},

}

module.exports = wc;
