const menu = {
	initReady() {
		this.menuInit();
	},

	menuInit() {
		$('.header__hamburger').on('click',() => {
			$('.header__hamburger, .header__mobile').toggleClass('opened');
			if($('.mobile-menu').is(':visible')) $('.mobile-menu').fadeOut(350)
			else $('.mobile-menu').fadeIn(350)
		})

		$('.header__area__top').on('click', function() {
			if($(this).hasClass('active')){
				$(this).removeClass('active')
				$(this).parent().find('.header__area__bottom').slideUp(400);
			} else {
				$('.header__area__top').removeClass('active')
				$('.header__area__bottom').slideUp(400);
				$(this).addClass('active')
				$(this).parent().find('.header__area__bottom').slideDown(400);
			}
		})
	},

}

module.exports = menu;
