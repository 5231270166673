import smoothState from 'smoothState';

const pageTransition = {
	initReady() {
		// this.initPageTransition();
	},

	initPageTransition() {
		const options = {
			anchors: 'a',
			forms: 'form',
			allowFormCaching: false,
			scroll: true,
		    blacklist: '.no-smoothState , .no-smoothState > a, .wpcf7-form',
		    prefetch: true,
	    	cacheLength: 20,
		    onBefore: function($currentTarget, $container) {
		    },
		    onStart: {
		    	duration: 400,
		    	render: function ($container) {
		    		if ($('.header__hamburger').hasClass('opened')) {
		    			$('.header__hamburger, .header, .header__menu').removeClass('opened');
				        setTimeout(function(){
			        		$('#main').addClass('is-exiting');
				        }, 200)
		    		} else {
			        	$('#main').addClass('is-exiting');
		    		}
		        }
		    },
		    onReady: {
		    	render: function ($container, $newContent) {
			        $container.html($newContent);
			    }
		    },
		    onAfter: function($container, $newContent){
		        $(document).trigger('rebuild');

		        setTimeout(function(){
		       		$('#main').removeClass('is-exiting');
		        }, 350);
		    }
		};

		$('#main').smoothState(options).data('smoothState');
  	},

}

export default pageTransition;
