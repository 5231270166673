import "intersection-observer";
import scrollama from "scrollama";
import "slick-carousel";

const common = {
  scroller: '',
  scroller2: '',

  initReady() {
		this.bindScrollingLink();
    this.setCurrentLabelMenu();
    this.initEnterViewport();
    this.initNumbersAnim();
    this.initDragElements();
    this.initModal();
    this.initJobModal();
    this.initCarousels();
	},
  goToPos: (ref, offset = 0) => {
    const $ref = (typeof ref === 'string') ? $(ref) : ref;

    if ($(ref).length === 0) return false;
    const myPos = $ref.offset().top - offset;

    $('html,body').animate({
      scrollTop: myPos,
    });
  },

  bindScrollingLink() {
    $(document)
      .on('click', '.goToLink', function () {
        const target = $(this).data('target');
        const offset = $(this).data('offset') || 0;

        if (typeof target !== 'undefined') {
          common.goToPos(target, offset);
        }
      });
  },

  setCurrentLabelMenu: () => {
    $('.header__menu > ul a.js-current-language').removeClass('js-current-language');
    $(`.header__menu > ul a[href="${window.location.href}"]`).addClass('js-current-language');
  },

  initEnterViewport() {
    const $el = $('.anim');
		if($el.length) {
      common.scroller = scrollama()
      common.scroller
        .setup({
          step: '.anim',
          once: true,
          offset: 0.8
        })
        .onStepEnter((response) => {
          // { element, index, direction }
          const delay = $(response.element).data('delay') * 1000 || 0

          setTimeout(() => {
            $(response.element).addClass('in-view')
          }, delay);
        })
    }
  },

  animateValue(el, start, end, duration) {
    // assumes integer values for start and end

    var range = end - start;
    // no timer shorter than 50ms (not really visible any way)
    var minTimer = 50;
    // calc step time to show all interediate values
    var stepTime = Math.abs(Math.floor(duration / range));

    // never go below minTimer
    stepTime = Math.max(stepTime, minTimer);

    // get current time and calculate desired end time
    var startTime = new Date().getTime();
    var endTime = startTime + duration;
    var timer;

    function run() {
        var now = new Date().getTime();
        var remaining = Math.max((endTime - now) / duration, 0);
        var value = Math.round(end - (remaining * range));
        if(el)
          el.innerHTML = value;
        if (value >= end) {
            clearInterval(timer);
        }
    }

    timer = setInterval(run, stepTime);
    run();
  },

  initNumbersAnim() {
    const $el = $('.numbers__number');
		if($el.length) {
      common.scroller2 = scrollama()
      common.scroller2
        .setup({
          step: '.numbers__number',
          once: true,
          offset: 1
        })
        .onStepEnter((response) => {
          const target = $(response.element).data('limit')

          common.animateValue($(response.element).find('span')[0], 0, target, 2000);
        })
    }
  },

  initDragElements() {
    if($('.draggableSlider').length == 0) return false

    $('.draggableSlider').each(function(i, el){
      const slider = el;
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener('mousedown', (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mousemove', (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    })
  },

  initModal() {
    const $el = $('.modal');
		if($el.length == 0) return false

    const $close = $('.modal__close')
    const $backdrop = $('.modal__backdrop')
    const $overlay = $('.members__image__overlay')

    $close.on('click', () => $el.fadeOut(400))
    $backdrop.on('click', () => $el.fadeOut(400))

    $overlay.on('click', function() {
      const image = $(this).parent().find('img').attr('src')
      const name = $(this).parent().parent().find('.members__name').html()
      const role = $(this).parent().parent().find('.members__role').html()
      const text = $(this).parent().parent().find('.members__text').html()

      $('.modal__image img').attr('src', image)
      $('.modal__title').html(name)
      $('.modal__subtitle').html(role)
      $('.modal__text').html(text)

      $el.fadeIn(450)
    })
  },

  initJobModal() {
    const $el = $('.modal-job');
		if($el.length == 0) return false

    const $close = $('.close')
    const $backdrop = $('.modal-job__backdrop')
    const $overlay = $('.single-position:not(.single-position--ended)')
    const $button = $('.modal-switch')
    const $back = $('.to-step-1')

    $close.on('click', () => $el.fadeOut(400))
    $backdrop.on('click', () => $el.fadeOut(400))

    $overlay.on('click', function() {
      const image = $(this).find('.single-position__image').data('image')
      const title = $(this).find('.single-position__title').html()
      const content = $(this).find('.single-position__text').html()
      const link = $(this).data('link')

      $('.modal-job__image img').attr('src', image)
      $('.modal-job__title').html(title)
      $('.modal-job__text').html(content)
      $('input[name="posizione-aperta"]').val(title)
      $button.attr('href', link)

      $el.fadeIn(450)
    })

    // $button.on('click', function() {
    //   $('.step-1').fadeOut(250, function(){
    //     $('.step-2').fadeIn(250);
    //   });
    // })

    // $back.on('click', function() {
    //   $('.step-2').fadeOut(250, function(){
    //     $('.step-1').fadeIn(250);
    //   });
    // })
  },

  initCarousels() {
    const $el = $('.js-carousel');
		if($el.length == 0) return false

    const visibleCards = $el.data('visible-cards') || 4
    const autoplay = $el.data('autoplay') || false
    $('.js-carousel').slick({
      dots: true,
      arrows: false,
      infinite: false,
      speed: 300,
      autoplay,
      slidesToShow: visibleCards,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }

}

module.exports = common;
