const news = {
	initReady() {
		this.initReadMore();
	},

	initReadMore() {
		$(document).on( 'click', '.load-more-news', function( event ) {
			event.preventDefault();
			let	curr_pagination = $('.load-more-news').data('page')
			$('.loader').addClass('loading');
			$('.load-more-news').animate({
				opacity: 0
			}, 100);

			$.ajax({
				url: '/wp-admin/admin-ajax.php',
				type: 'POST',
				data: {
					action: 'load_more_news',
					page: curr_pagination,
				},
				success: function( html ) {
					$('.loader').removeClass('loading');
					curr_pagination++;
					$('.load-more-news').data('page', curr_pagination);
					setTimeout(function(){
						$('.news-list__content').append(html);
						$('.load-more-news').animate({
							opacity: 1
						}, 100);
					}, 150);
				}
			})
		})
	},

}

module.exports = news;
