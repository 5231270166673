import Isotope from 'isotope-layout';

const product = {
	initReady() {
		this.initReadMore();
	},

	initLoad() {
		this.preSelectProductForm();
		this.initMasonry()
	},

	initReadMore() {
		if($('.readmore').length == 0) return false

		$('.readmore__heading').on('click', function(){
			const $el = $(this).parent().find('.readmore__body');
			const plusTxt = $(this).find('span').data('plus')
			const minusTxt = $(this).find('span').data('minus')

			if($el.is(':visible')){
				$(this).removeClass('active')
				$(this).parent().find('.readmore__body').slideUp(350)
				$(this).find('span').html(plusTxt)
			}else{
				$(this).addClass('active')
				$(this).parent().find('.readmore__body').slideDown(350)
				$(this).find('span').html(minusTxt)
			}
		})
	},

	preSelectProductForm() {
		if($('.cf7-product-list').length == 0) return false

		const id = $('#contact').data('product')
		$('.cf7-product-list option').each((i, el) => {
			if(id == $(el).attr('value')) $(el).attr('selected', true)
		})
	},

	initMasonry() {
		if($('.masonry').length == 0) return false
		var iso = new Isotope( '.masonry', {
			itemSelector: '.book',
			percentPosition: true,
		})
	}

}

module.exports = product;
